/* eslint-disable react-hooks/exhaustive-deps */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const Layout = dynamic(() => import('@/components/Layout'));
const Error = dynamic(() => import('@/components/Error'));

const Custom404 = () => {
  const router = useRouter();

  useEffect(() => {
    router.replace('/404');
  }, []);

  return (
    <Layout
      title='Lost? Let Us Help You Find Your Way Back'
      description="Page Not Found: Keep Exploring Our Site - the page you're looking for can't be found, but there's plenty more to explore on our site."
    >
      <Error />
    </Layout>
  );
};

export default Custom404;
